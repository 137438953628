<script setup lang="ts">
import { ref } from 'vue';

import Heading from '@components/Heading.vue';

const gabrielCamLogo = new URL('/src/assets/logos/gabrielcam-mark.png', import.meta.url).href;
const iris5Logo = new URL('/src/assets/logos/iris5-inverted-logo.png', import.meta.url).href;

const hostname = window.location.hostname;
const logoSrc = ref<string>('');

// Iris5 logo
if (hostname.includes('iris5')) {
  logoSrc.value = iris5Logo;
  // Gabrielcam logo for hub or localhost
} else if (hostname.includes('hub') || hostname.includes('localhost')) {
  logoSrc.value = gabrielCamLogo;
  // Fallback logo
} else {
  logoSrc.value = gabrielCamLogo;
}
</script>

<template>
  <section class="account-container">
    <img :src="logoSrc"
         alt=""
         aria-hidden="true"
         width="480">
    <Heading level="1"
             :has-bottom-margin="true">
      {{ $route.meta['title'] }}
    </Heading>

    <slot />
  </section>
</template>

<style lang="scss">
@use '@scss/variables' as *;

.account-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;
  margin: 0;
  padding: clamp($gap-mobile, 2vw, $gap-desktop);

  & img {
    width: 240px;
    max-width: 100%;
    margin-bottom: $margin-bottom;
  }

  h1 {
    font-size: 2.4em;
    line-height: 1.2;
  }

  .account-form {
    width: min(100%, 450px);
    margin-bottom: $margin-bottom;
    padding: 30px clamp($gap-mobile, 2vw, $gap-desktop) 30px;
    border: 1px solid $neutral-600;
    border-radius: 10px;
    background: $neutral-900;
    box-shadow: $box-shadow;
  }

  .account-link-container {
    margin-bottom: $margin-bottom;

    & .account-link {
      &:link,
      &:visited {
        color: $neutral-50;
        text-decoration: none;
      }

      &:hover,
      &:focus,
      &:focus-visible {
        color: $neutral-300;
        text-decoration: underline;
        outline: none;
      }
    }
  }

  .account-footer {
    display: flex;
    flex-direction: column-reverse;
    gap: $gap-default;

    @media screen and (min-width: $breakpoint-lg) {
      flex-direction: row;
    }
  }
}
</style>

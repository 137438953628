<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import * as client from '@gabrielcam/api-client';

import { convertToScheduleDto } from '@utils/ScheduleMapper';

import { useApplicationStore } from '@stores/application';
import { useScheduleServiceStore } from '@stores/schedule';
import { ButtonVariant } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';
import CameraScheduleSettings from '@components/camera/CameraScheduleSettings.vue';
import HeaterScheduleSettings from '@components/camera/HeaterScheduleSettings.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Loading from '@components/Loading.vue';
import ButtonActions from '@layouts/ButtonActions.vue';

const isSubmitting = ref<boolean>(false);
const route = useRoute();
const cameraId = route.params['id'] as string;
const currentCamera = ref<client.Camera>();
const isLoading = ref<boolean>(true);
const shadow = ref<client.Shadow>();
const applicationStore = useApplicationStore();
const scheduleServiceStore = useScheduleServiceStore();

// Get current camera data
onMounted(async () => {
  try {
    currentCamera.value = await client.getCameraById({ cameraId });
  } catch (error) {
    console.error('Error fetching camera data:', error);
  } finally {
    isLoading.value = false;
  }
});

async function handleSubmit(): Promise<void> {
  const schedule = scheduleServiceStore.monthlySheduleSections.at(0); // handle ALL months only for now

  if (!schedule) return;

  const dto = convertToScheduleDto(schedule);
  var desired = shadow?.value?.state?.reported ?? {};
  desired['scheduleStartTime'] = dto.scheduleStartTime;
  desired['scheduleEndTime'] = dto.scheduleEndTime;
  desired['scheduleRate'] = dto.scheduleRate;
  desired['scheduleDays'] = dto.scheduleDays;

  isSubmitting.value = true;
  try {
    shadow.value = await client.updateCameraByIdShadow({
      cameraId,
      requestBody: {
        state: {
          desired: desired,
        },
      },
    });

    isSubmitting.value = false;

    applicationStore.publishSuccessNotification({
      text: 'Successfully updated camera.',
      autoCloseMs: 3000,
    });
  } catch (error: any) {
    if (error instanceof client.ApiError) {
      // @ts-ignore
      applicationStore.publishErrorNotification({ text: error.body.error.message });
    }
    applicationStore.publishErrorNotification({ text: 'UNKNOWN ERROR' });
    isSubmitting.value = false;
    return;
  }
}
</script>

<template>
  <section>
    <ContainerCard>
      <Loading v-if="isLoading" />

      <section v-else>
        <form>
          <CameraScheduleSettings :camera-id="cameraId" />
          <HeaterScheduleSettings />

          <ButtonActions>
            <ButtonComponent :variant="ButtonVariant.Dark"
                             :disabled="isSubmitting"
                             :is-block-btn="true"
                             @click="handleSubmit">
              Update
            </ButtonComponent>
          </ButtonActions>
        </form>
      </section>
    </ContainerCard>
  </section>
</template>

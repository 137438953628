<script lang="ts" setup>
import { onMounted, ref } from 'vue';

import { useScheduleServiceStore } from '@stores/schedule';
import { ScheduleViewModel } from '@viewModels/scheduleViewModel';

import HeaterSchedulePendingSection from '@components/camera/HeaterSchedulePendingSection.vue';
import Heading from '@components/Heading.vue';

import HeaterScheduleSection from './HeaterScheduleSection.vue';

const scheduleStore = useScheduleServiceStore();
var allScheduleSections: ScheduleViewModel[] = [];
const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
const hasMonthlySchedule = ref(false);

function Setup(): void {
  if (scheduleStore.advancedHeaterScheduleEnabled) {
    SetupAdvancedSchedules();
    return;
  }
  SetupSingleSchedule();
}

function SetupSingleSchedule(): void {
  var schedule: ScheduleViewModel = new ScheduleViewModel();
  if (allScheduleSections.length !== 0) schedule = allScheduleSections[0] ?? new ScheduleViewModel();
  schedule.month = 'ALL';
  scheduleStore.monthlyHeaterSheduleSections.push(schedule);
}

function SetupAdvancedSchedules(): void {
  months.forEach((month) => {
    var currentMonths = allScheduleSections.filter((x) => x.month.toUpperCase() == month.toUpperCase());
    if (currentMonths.length === 0 || currentMonths.at(0) == undefined) {
      const defaultHeaterSchedule: ScheduleViewModel = new ScheduleViewModel();
      defaultHeaterSchedule.month = month.toUpperCase();
      defaultHeaterSchedule.captureRate = 0;
      scheduleStore.monthlyHeaterSheduleSections.push(defaultHeaterSchedule);
      return;
    }
    const currentMonth = currentMonths.at(0);
    if (currentMonth != undefined) scheduleStore.monthlyHeaterSheduleSections.push(currentMonth);
  });
}

onMounted(async () => {
  scheduleStore.monthlyHeaterSheduleSections = [];
  allScheduleSections = await scheduleStore.GetHeaterSchedules();
  Setup();
});

function openMonth(month: string): void {
  visibleMonth.value = month.toUpperCase();
}

function toggleAdvancedHeaterSchedule(): void {
  scheduleStore.monthlyHeaterSheduleSections = [];
  Setup();
}

const date = new Date();
const month = date.toLocaleString('default', { month: 'short' });
const visibleMonth = ref<string>(month.toUpperCase());
</script>

<template>
  <div class="field-group">
    <div class="field-group-info">
      <Heading :has-bottom-margin="false"
               level="3">
        Heater Schedule
      </Heading>

      <p>Choose the days and the time that you'd like to turn the heater on.</p>
      <div v-if="hasMonthlySchedule"
           class="fields">
        <div class="checkbox-field">
          <label id="input-vname"
                 for="advanced-heater">Monthly Schedule</label>
          <input id="advanced-heater"
                 v-model="scheduleStore.advancedHeaterScheduleEnabled"
                 type="checkbox"
                 @change="toggleAdvancedHeaterSchedule">
        </div>
      </div>
    </div>
    <div :class="{ 'hidden-navigation': !scheduleStore.advancedHeaterScheduleEnabled }"
         class="tabs">
      <div class="tab-navigation">
        <button v-for="currentMonth in months"
                :key="currentMonth"
                :class="{ active: currentMonth == visibleMonth }"
                class="tab-button"
                type="button"
                @click="openMonth(currentMonth)">
          {{ currentMonth }}
        </button>
      </div>
      <div class="tab-content">
        <div v-for="scheduleSection in scheduleStore.monthlyHeaterSheduleSections"
             :id="scheduleSection.month"
             :key="scheduleSection.month"
             class="tab">
          <div v-show="scheduleSection.month === visibleMonth || !scheduleStore.advancedHeaterScheduleEnabled"
               class="tab-panel">
            <HeaterScheduleSection :heater-schedule="scheduleSection" />
            <HeaterSchedulePendingSection v-if="false"
                                          :heater-schedule="scheduleSection" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.tabs {
  width: 100%;

  .tab-navigation {
    .tab-button {
      font-size: 0.7em;
      background: #fff;
      color: $neutral-800;
      border: none;
      border-radius: 5px 5px 0 0;
      padding: 10px 2px;
      margin: 0 6px 0 0;
      outline: 1px solid $neutral-200;

      @media screen and (min-width: 500px) {
        font-size: 0.8em;
      }

      @media screen and (min-width: 550px) {
        padding: 10px 4px;
      }

      @media screen and (min-width: 620px) {
        width: 7.2%;
      }

      &:hover,
      &:focus,
      &:focus-visible,
      &.active {
        background-color: $neutral-200;
      }
    }
  }

  .tab-content {
    background: #fff;
    border: 1px solid $neutral-200;
    padding: 15px;
    margin: -2px 0 0 -1px;
    position: relative;
  }
}

.hidden-navigation .tab-navigation {
  display: none;
}
</style>

<script setup lang="ts">
import { ref, watch } from 'vue';

import { ButtonSize, ButtonVariant } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';

/*
TODO - Change this to emit and not take a function as a prop
*/
/**
 * We need an optional id reference, and a search function
 */
const props = defineProps({
  onSearchFunction: {
    type: Function,
    default: () => {},
  },
  onClearFunction: {
    type: Function,
    default: () => {},
  },
});

/**
 * A push event to notify the parents of when the selector is activated
 */
const push = defineEmits(['onSearched']);

/**
 * The name of the viewer we want to find
 */
const viewerName = ref<string>();

/**
 * This method is used to search filter the viewers we need
 */
async function onSearch(): Promise<void> {
  if (viewerName.value && props.onSearchFunction) {
    const outcome = await props.onSearchFunction(viewerName.value);
    push('onSearched', outcome);
  }
}

/**
 * We create a props watch to override the internal index if needed
 */
watch(
  () => viewerName.value,
  async (newValue) => {
    if (newValue === '' && props.onClearFunction) {
      await props.onClearFunction();
    }
  }
);
</script>

<template>
  <div class="search">
    <input v-model="viewerName"
           type="search"
           placeholder="&nbsp;"
           @keydown.enter="onSearch">

    <ButtonComponent :variant="ButtonVariant.Dark"
                     :size="ButtonSize.Small"
                     @click="onSearch">
      Search
    </ButtonComponent>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, PropType, ref } from 'vue';
import Popper from 'vue3-popper';

import { storeToRefs } from 'pinia';
import 'v-calendar/style.css';

import * as client from '@gabrielcam/api-client';

import { useViewStore } from '@stores/view';

import {
  ArrowDownOnSquareIcon,
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  BackwardIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ForwardIcon,
  InformationCircleIcon,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
  ViewColumnsIcon,
  ViewfinderCircleIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/vue/24/solid';

import ViewerCatalogue from './ViewerCatalogue.vue';
import ViewerFrame from './ViewerFrame.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import { ButtonVariant } from '@viewModels/enums';

const props = defineProps({
  dates: {
    type: Object as PropType<string[]>,
    required: true,
  },
  initDate: {
    type: String,
    required: true,
  },
  images: {
    type: Object as PropType<Map<string, client.Image[]>>,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  view: {
    type: Object as PropType<client.View>,
    required: true,
  },
});

const viewStore = useViewStore();

const { datePickerIsOpen } = storeToRefs(viewStore);
const frameElementRef = ref<InstanceType<typeof ViewerFrame>>();
const leftSelectedImage = ref<client.Image>();
const rightSelectedImage = ref<client.Image>();
const comparisonLeftActive = ref<boolean>(true);

const controls = { visible: true };
const height = ref(601);
const maxScale = 10;
const menu = { visible: true };
const overlay = ref(false);

const showMenuLeft = ref(false);
const showMenuRight = ref(false);
const wall = ref({ fullscreen: false });
const zoom = ref({ scale: 1, x: 0, y: 0 });
const selectedDateLeftImage = ref<string>(props.initDate);
const selectedDateRightImage = ref<string>(props.initDate);

onMounted(() => {
  goToImage('ffw');

  getViewerHeight();
  window.addEventListener('resize', () => {
    getViewerHeight();
  });
});

const frameStyle = computed(() => {
  const position = overlay.value ? 'absolute' : 'relative';
  const size = {
    height: overlay.value ? '100%' : 'auto',
    width: '100%',
  };
  const border = {};
  return {
    ...border,
    position,
    ...size,
  };
});

const selectedImage = computed({
  get() {
    if (comparisonLeftActive.value) return leftSelectedImage.value;
    return rightSelectedImage.value;
  },
  // setter
  set(newValue) {
    if (comparisonLeftActive.value) {
      leftSelectedImage.value = newValue;
    } else {
      rightSelectedImage.value = newValue;
    }
  },
});
const selectedDate = computed({
  get() {
    if (comparisonLeftActive.value) return selectedDateLeftImage.value;
    return selectedDateRightImage.value;
  },
  // setter
  set(newValue) {
    if (comparisonLeftActive.value) {
      selectedDateLeftImage.value = newValue;
    } else {
      selectedDateRightImage.value = newValue;
    }
  },
});

const zoomControls = computed(() => {
  return {
    fit: {
      action: 'reset',
      icon: ViewfinderCircleIcon,
      title: 'Reset Zoom',
    },
    zoomOut: {
      action: 'out',
      icon: MagnifyingGlassMinusIcon,
      title: 'Zoom Out',
    },
    zoomIn: {
      action: 'in',
      icon: MagnifyingGlassPlusIcon,
      title: 'Zoom In',
    },
    fullscreen: {
      action: 'fullscreen',
      icon: wall.value.fullscreen ? ArrowsPointingInIcon : ArrowsPointingOutIcon,
      title: wall.value.fullscreen ? 'Exit Fullscreen' : 'Fullscreen',
    },
  };
});

const downloadImageLink = computed(() => {
  if (selectedImage.value == undefined) return;
  return `${selectedImage.value.sourceURL}?dl=${selectedImage.value.originalFileName}`;
});

function catalogDateSelected(value: string): void {
  selectedDate.value = value;
}

function getViewerHeight(): void {
  const getViewer = setInterval(() => {
    const viewer: any = document.querySelector('.viewer');
    if (viewer) {
      height.value = viewer.offsetHeight;
      clearInterval(getViewer);
    }
  });
}

async function goToImage(dest: string | number): Promise<void> {
  if (typeof dest === 'number') {
    const images = props.images.get(selectedDate.value);
    const selectedImageIndex =
      selectedImage.value == undefined ? 0 : images!.findIndex((image) => image.id == selectedImage.value!.id);
    const newImage = images![selectedImageIndex + dest];
    if (newImage == undefined) return;
    selectImage(newImage);
  }

  if (dest === 'fbw') {
    const date = props.dates[0];
    selectedDate.value = date!;
    const waitForImages = setInterval(() => {
      if (props.images.get(date!)) {
        const newImage = props.images!.get(date!)![0];
        if (newImage) {
          // Check if newImage is not undefined
          selectImage(newImage);
          clearInterval(waitForImages);
        }
      }
    }, 50);
  }

  if (dest === 'ffw') {
    const date = props.dates[props.dates.length - 1];
    selectedDate.value = date!;
    const newImage = props.images.get(date!)![props.images.get(date!)!.length - 1];
    if (newImage == undefined) return;
    setTimeout(() => selectImage(newImage), 500);
  }
}

function selectImage(image: client.Image): void {
  datePickerIsOpen.value = false;
  if (!image) return;
  if (comparisonLeftActive.value) leftSelectedImage.value = image;
  else rightSelectedImage.value = image;
  selectedImage.value = image;
}

function toggleFullscreen(): void {
  wall.value.fullscreen = !wall.value.fullscreen;
  frameElementRef.value?.init();
}

function toggleMenu(side: string): void {
  if (side === 'left') {
    showMenuLeft.value = !showMenuLeft.value;
    showMenuRight.value = false;
  } else if (side === 'right') {
    showMenuRight.value = !showMenuRight.value;
    showMenuLeft.value = false;
  }
}

function zoomAction(action: string): void {
  if (!frameElementRef.value) return;

  switch (action) {
    case 'fullscreen':
      toggleFullscreen();
      break;
    case 'in':
      zoomIn();
      break;
    case 'out':
      zoomOut();
      break;
    case 'reset':
      frameElementRef.value.init();
      break;
  }
}

function zoomChange({ scale, x, y }: { scale: number; x: number; y: number }): void {
  if (!frameElementRef.value) return;

  zoom.value = { scale, x, y };
  frameElementRef.value.setTransform({ scale, x, y });
}

function zoomIn(): void {
  if (!frameElementRef.value) return;

  frameElementRef.value.scaleTo(zoom.value.scale * 1.38, {
    originX: '50%',
    originY: '50%',
    relativeTo: 'container',
    allowChangeEvent: true,
  });
}

function zoomOut(): void {
  if (!frameElementRef.value) return;

  frameElementRef.value.scaleTo((zoom.value.scale * 1) / 1.38, {
    originX: '50%',
    originY: '50%',
    relativeTo: 'container',
    allowChangeEvent: true,
  });
}
function toggleImageComparison(): void {
  if (rightSelectedImage.value) {
    rightSelectedImage.value = undefined;
    return;
  }
  rightSelectedImage.value = selectedImage.value;
}
</script>

<template>
  <div class="viewer"
       :class="{ 'viewer-fullscreen': wall.fullscreen }">
    <div class="viewer-sidebar"
         :class="{ 'is-open': datePickerIsOpen }">
      <ViewerCatalogue v-if="wall.fullscreen == false && selectedImage"
                       :dates="dates"
                       :selected-image="selectedImage"
                       :selected-date="selectedDate"
                       :images="images"
                       @image-selected="selectImage"
                       @selected-date-changed="catalogDateSelected" />
    </div>
    <!-- VIEWER WALL -->

    <div class="viewer-wall">
      <ViewerFrame v-if="selectedImage"
                   ref="frameElementRef"
                   :view-name="props.title"
                   class="viewer-frame"
                   :left-image="leftSelectedImage!"
                   :right-image="rightSelectedImage"
                   :image-date="selectedImage.capturedAt!"
                   :max-scale="maxScale"
                   :overlaid="overlay"
                   :style="frameStyle"
                   @zoom-change="zoomChange">
        <template v-if="images.get(selectedDate) && !overlay"
                  #frame-controls>
          <div style="position: absolute; left: 15px; top: 50%">
            <button v-if="selectedImage.id != images.get(selectedDate)![0]!.id"
                    title="Previous image"
                    class="icon-button"
                    @click="goToImage(-1)">
              <ChevronLeftIcon />
            </button>
          </div>
          <div style="position: absolute; right: 15px; top: 50%">
            <button v-if="selectedImage.id != images.get(selectedDate)!.at(images.get(selectedDate)!.length - 1)!.id"
                    title="Next image"
                    class="icon-button"
                    @click="goToImage(1)">
              <ChevronRightIcon />
            </button>
          </div>
        </template>
      </ViewerFrame>

      <div class="viewer-menu">
        <div class="viewer-menu-left">
          <div v-if="menu.visible"
               style="display: contents">
            <Popper content="Show Image Controls"
                    hover
                    placement="left"
                    class="is-hidden-desktop">
              <button title="Show Image Controls"
                      class="icon-button"
                      @click="toggleMenu('left')">
                <WrenchScrewdriverIcon />
              </button>
            </Popper>
            <div style="display: contents"
                 :class="{ 'is-hidden-touch': !showMenuLeft }">
              <!-- <Popper content="Show image chooser" hover placement="bottom" style="margin-left: 2em">
                <button
                  title="Show image chooser"
                  class="icon-button"
                  @click="catalogue.visible = true"
                  v-show="!catalogue.visible">
                  <CalendarDaysIcon />
                </button>
              </Popper> -->

              <!-- <Popper content="Hide image chooser" placement="bottom">
                <button
                  title="Hide image chooser"
                  class="icon-button"
                  @click="catalogue.visible = false"
                  v-show="catalogue.visible">
                  <CalendarIcon />
                </button>
              </Popper> -->

              <Popper content="First available image"
                      hover
                      arrow
                      offset-skid="0"
                      placement="bottom">
                <button title="First available image"
                        class="icon-button"
                        style="margin-bottom: 0.5em"
                        @click="goToImage('fbw')">
                  <BackwardIcon />
                </button>
              </Popper>

              <Popper content="Latest image"
                      hover
                      arrow
                      offset-skid="0"
                      placement="bottom">
                <button title="Latest image"
                        class="icon-button"
                        style="margin-bottom: 0.5em"
                        @click="goToImage('ffw')">
                  <ForwardIcon />
                </button>
              </Popper>

              <!-- Todo: Comparison      -->
              <Popper content="Compare images side-by-side"
                      hover
                      arrow
                      offset-skid="30"
                      placement="bottom">
                <button title="Compare images side-by-side"
                        class="icon-button"
                        @click="toggleImageComparison()">
                  <ViewColumnsIcon />
                </button>
              </Popper>

              <!-- <Popper content="Compare images overlaid" hover arrow placement="bottom">
                <button title="Compare images overlaid" class="icon-button" @click="overlayFrames()">
                  <DocumentDuplicateIcon />
                </button>
              </Popper> -->

              <Popper content="Download selected image"
                      hover
                      arrow
                      placement="bottom">
                <a v-if="!overlay"
                   :href="downloadImageLink"
                   target="_blank">
                  <button title="Download selected image"
                          class="icon-button">
                    <ArrowDownOnSquareIcon />
                  </button>
                </a>
              </Popper>

              <!--               <Popper content="Viewer/Image Embed Code" hover arrow placement="bottom">
                <button
                  title="Viewer/Image Embed Code"
                  class="icon-button"
                  v-if="overlay == false"
                  @click="showEmbed">
                  <CodeBracketIcon />
                </button>
              </Popper> -->
            </div>
          </div>
        </div>

        <div v-if="menu.visible"
             class="viewer-menu-right">
          <div v-if="controls.visible"
               class="viewer-menu-zoom">
            <div style="display: contents"
                 :class="{ 'is-hidden-touch': !showMenuRight }">
              <Popper content="Double-click the image to center and zoom in on the point clicked. Scroll using mousewheel or touchpad to zoom on the center of the frame."
                      hover
                      arrow
                      placement="bottom"
                      multilined
                      class="is-hidden-mobile">
                <button title="Zoom Help"
                        class="icon-button"
                        style="cursor: default">
                  <InformationCircleIcon />
                </button>
              </Popper>

              <Popper v-for="(control, key) in zoomControls"
                      :key="key"
                      :content="control.title"
                      hover
                      arrow
                      placement="bottom">
                <button :title="control.title"
                        class="icon-button"
                        @click="zoomAction(control.action)">
                  <component :is="control.icon" />
                </button>
              </Popper>
            </div>
            <Popper content="Show Zoom Controls"
                    hover
                    arrow
                    placement="left"
                    class="is-hidden-desktop"
                    :class="{ 'is-hidden-mobile': showMenuRight }">
              <button title="Show Zoom Controls"
                      class="icon-button"
                      @click="toggleMenu('right')">
                <MagnifyingGlassPlusIcon />
              </button>
            </Popper>
          </div>
        </div>

        <div v-if="leftSelectedImage && rightSelectedImage"
             class="viewer-menu-comparison">

          <ButtonComponent :variant="comparisonLeftActive ? ButtonVariant.Danger : ButtonVariant.Dark"
                           @click="() => (comparisonLeftActive = true)">
            Left
          </ButtonComponent>
          <ButtonComponent :variant="!comparisonLeftActive ? ButtonVariant.Danger : ButtonVariant.Dark"
                           @click="() => (comparisonLeftActive = false)">
            Right
          </ButtonComponent>
        </div>
      </div>

      <!-- <div
        class="overlay-blender"
        v-if="overlay"
        style="
          position: absolute;
          bottom: 1em;
          left: 50%;
          transform: translateX(-50%);
          width: 50%;
          text-align: center;
          padding: 1em;
        ">
        <label class="label" for="image-blender">
          <span>Image Blender</span>
          <input
            id="image-blender"
            name="image-blender"
            type="range"
            min="-0.25"
            :max="frames.length - 1.25"
            :step="0.01 * (frames.length - 1)"
            v-model="blend"
            style="width: 100%" />
        </label>

        <p style="display: flex; justify-content: space-around; position: relative">
          <span
            v-for="frame in frames"
            :key="frame['id']"
            style="
              position: relative;
              padding: 0.2em 0.5em;
              background: rgb(255, 255, 255);
              border-radius: 2em;
              display: inline-block;
            ">
            <span
              style="
                height: 10px;
                width: 10px;
                position: absolute;
                top: -10px;
                background: white;
                border-radius: 50% 50% 0 0;
              "
              :style="{ left: '50%' }"></span>
            {{ formatDate(frame['image'].meta.date) }}
          </span>
        </p>
      </div> -->
    </div>
  </div>
</template>

<script setup lang="ts">
import { Ref, ref } from 'vue';

import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';

import { ButtonType, ButtonVariant } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';
import ButtonActions from '@layouts/ButtonActions.vue';

const props = defineProps<{
  signIn: Function;
}>();

const emit = defineEmits<{
  (e: 'onSuccess'): void;
  (e: 'onBack'): void;
  (e: 'update:email', value: String): void;
  (e: 'update:password', value: String): void;
}>();

const isSubmitting: Ref<boolean> = ref(false);

interface LoginWithEmailAndPasswordForm {
  email: string;
  password: string;
}

const schema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const { handleSubmit } = useForm<LoginWithEmailAndPasswordForm>({
  validationSchema: schema,
});

const { value: emailValue, errorMessage: emailError } = useField<string>('email', 'email', {
  validateOnValueUpdate: false,
  modelPropName: 'email',
});

const { value: passwordValue, errorMessage: passwordError } = useField<string>('password', 'password', {
  modelPropName: 'password',
});

const onSubmit = handleSubmit(async (values, actions) => {
  isSubmitting.value = true;
  try {
    await props.signIn(values.email, values.password);
    emit('onSuccess');
  } catch (err) {
    actions.setErrors({ email: 'Error occurred' });
    console.log(err);
    return;
  } finally {
    isSubmitting.value = false;
  }
});
</script>

<template>
  <form @submit="onSubmit">
    <div class="fields">
      <div class="field">
        <label for="email-address">Email address</label>
        <input id="email-address"
               v-model="emailValue"
               type="email"
               autocomplete="email">
        <p class="message message-error">
          {{ emailError }}
        </p>
      </div>
      <div class="field">
        <label for="password">Password</label>
        <input id="password"
               v-model="passwordValue"
               type="password"
               autocomplete="current-password">
        <p class="message message-error">
          {{ passwordError }}
        </p>
      </div>
    </div>

    <ButtonActions>
      <ButtonComponent :disabled="isSubmitting"
                       :loading="isSubmitting"
                       :type="ButtonType.Submit"
                       :variant="ButtonVariant.Dark"
                       :is-block-btn="true">
        Login
      </ButtonComponent>
    </ButtonActions>
  </form>
</template>

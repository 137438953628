<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { useForm } from 'vee-validate';
import * as yup from 'yup';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { CameraUpdateForm } from '@viewModels/cameraUpdateForm';
import { ButtonType, ButtonVariant } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';
import CameraGeneralSettings from '@components/camera/CameraGeneralSettings.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Loading from '@components/Loading.vue';
import ButtonActions from '@layouts/ButtonActions.vue';

const { handleSubmit } = useForm<CameraUpdateForm>({
  validationSchema: yup.object({
    name: yup.string().required(),
    serialNumber: yup.string().required(),
    imei: yup.string().required(),
    make: yup.string().required('Make name is a required field').typeError('Model name is a required field'),
    model: yup.string().required('Model name is a required field').typeError('Model name is a required field'),
  }),
});

const route = useRoute();
const applicationStore = useApplicationStore();
const isSubmitting = ref<boolean>(false);
const cameraId = route.params['id'] as string;
const currentCamera = ref<client.Camera>();
const isLoading = ref<boolean>(true);

const onSubmit = handleSubmit(async () => {
  isSubmitting.value = true;
  if (!applicationStore.activeOrganisation) return;

  const requestBody: client.UpdateCameraRequest = {};

  try {
    await client.updateCameraById({ cameraId, requestBody });
    isSubmitting.value = false;

    applicationStore.publishSuccessNotification({
      text: 'Successfully updated camera.',
      autoCloseMs: 3000,
    });
  } catch (error: any) {
    if (error instanceof client.ApiError) {
      // @ts-ignore
      applicationStore.publishErrorNotification({ text: error.body.error.message });
    }
    applicationStore.publishErrorNotification({ text: 'UNKNOWN ERROR' });
    isSubmitting.value = false;
    return;
  }
});

// Get the current camera
onMounted(async () => {
  try {
    currentCamera.value = await client.getCameraById({ cameraId });
  } finally {
    isLoading.value = false;
  }
});
</script>

<template>
  <ContainerCard>
    <Loading v-if="isLoading" />

    <section v-else>
      <form @submit="onSubmit">
        <CameraGeneralSettings v-if="currentCamera != undefined"
                               :camera="currentCamera" />

        <ButtonActions v-if="false">
          <ButtonComponent :variant="ButtonVariant.Dark"
                           :disabled="isSubmitting"
                           :is-block-btn="true"
                           :type="ButtonType.Submit">
            Update
          </ButtonComponent>
        </ButtonActions>
      </form>
    </section>
  </ContainerCard>
</template>

<style lang="scss" scoped>
.field-group {
  border-bottom: none;
  margin: 0;
}
</style>

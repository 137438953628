<script setup lang="ts">
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';

import { ButtonType, ButtonVariant } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';

defineProps<{
  forename: string;
  surname: string;
}>();

const emit = defineEmits<{
  (e: 'onSuccess'): void;
  (e: 'update:forename', value: String): void;
  (e: 'update:surname', value: String): void;
}>();

interface RegisterUserForm {
  forename: string;
  surname: string;
}

const schema = yup.object({
  forename: yup.string().required(),
  surname: yup.string().required(),
});

const { handleSubmit } = useForm<RegisterUserForm>({
  validationSchema: schema,
});

const { value: forenameValue, errorMessage: forenameError } = useField<string>('forename', 'forename', {
  modelPropName: 'forename',
});

const { value: surnameValue, errorMessage: surnameError } = useField<string>('surname', 'surname', {
  modelPropName: 'surname',
});

const onSubmit = handleSubmit(() => {
  emit('onSuccess');
});
</script>

<template>
  <form @submit.prevent="onSubmit">
    <div class="fields">
      <div class="field">
        <label for="forename">First Name</label>
        <input id="forename"
               v-model="forenameValue"
               type="text"
               autocomplete="given-name">
        <p class="message message-error">
          {{ forenameError }}
        </p>
      </div>
    </div>
    <div class="fields">
      <div class="field">
        <label for="surname">Last Name</label>
        <input id="surname"
               v-model="surnameValue"
               type="text"
               autocomplete="family-name">
        <p class="message message-error">
          {{ surnameError }}
        </p>
      </div>
    </div>

    <div class="account-footer">
      <ButtonComponent :type="ButtonType.Submit"
                       :variant="ButtonVariant.Dark"
                       :is-block-btn="true">
        Next
      </ButtonComponent>
    </div>
  </form>
</template>

<script setup lang="ts">
import { onMounted, Ref, ref } from 'vue';
import { useRoute } from 'vue-router';

import { confirmPasswordReset, getAuth } from 'firebase/auth';

import * as client from '@gabrielcam/api-client';

import { firebaseApp } from '@utils/firebase';

import { useApplicationStore } from '@stores/application';
import { ButtonType, ButtonVariant, PageNames } from '@viewModels/enums';

import ResetPasswordEmailForm from '@components/account_forms/ResetPasswordEmailForm.vue';
import ResetPasswordForm from '@components/account_forms/ResetPasswordForm.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import Heading from '@components/Heading.vue';
import Loading from '@components/Loading.vue';
import AccountLayout from '@layouts/AccountLayout.vue';
import ButtonActions from '@layouts/ButtonActions.vue';

enum ResetStep {
  SendLink = 1,
  EmailSent = 2,
  Reset = 3,
  Success = 4,
}

const resetStep = ref(ResetStep.SendLink);
const oobCode: Ref<string | undefined> = ref();
const userEmail: Ref<string | undefined> = ref();

const route = useRoute();
const applicationStore = useApplicationStore();

const sendResetEmail = async (email: string): Promise<void> => {
  await client.resetPassword({ requestBody: { email } });
  userEmail.value = email;
  resetStep.value = ResetStep.EmailSent;
};

const resetPassword = async (newPassword: string): Promise<void> => {
  const authProvider = getAuth(firebaseApp);
  await confirmPasswordReset(authProvider, oobCode.value!, newPassword);
  resetStep.value = ResetStep.Success;
};

onMounted(async () => {
  if (route.query['oobCode']) {
    oobCode.value = String(route.query['oobCode']);
    resetStep.value = ResetStep.Reset;
    return;
  }
});
</script>

<template>
  <Loading v-if="!applicationStore.applicationReady" />

  <AccountLayout v-else>
    <div class="account-form">
      <template v-if="resetStep === ResetStep.SendLink">
        <ResetPasswordEmailForm :send-reset-email="sendResetEmail" />
      </template>

      <template v-else-if="resetStep === ResetStep.EmailSent">
        <Heading level="2">
          Check your mail
        </Heading>
        <p>
          We have sent password recovery instructions to your inbox at
          <em>{{ userEmail }}</em>.
        </p>
      </template>

      <template v-else-if="resetStep === ResetStep.Reset">
        <ResetPasswordForm :reset-password="resetPassword" />
      </template>

      <template v-else-if="resetStep === ResetStep.Success">
        <Heading level="2">
          Success!
        </Heading>
        <p>You'll need to login again to continue</p>

        <ButtonActions>
          <ButtonComponent :variant="ButtonVariant.Dark"
                           :to="{ name: PageNames.Login }"
                           :is-block-btn="true"
                           :type="ButtonType.Submit">
            Login
          </ButtonComponent>
        </ButtonActions>
      </template>
    </div>
    <p class="account-link-container">
      <router-link to="/login"
                   class="account-link">
        Back to login?
      </router-link>
    </p>
  </AccountLayout>
</template>

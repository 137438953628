<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { useImageStore } from '@stores/image';
import { ButtonVariant, PageNames } from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';

import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import GalleryCard from '@components/cards/galleryCard/GalleryCard.vue';
import Directory from '@components/directory/Directory.vue';
import GalleryDatePicker from '@components/gallery/GalleryDatePicker.vue';
import GalleryDirectoryPagination from '@components/gallery/GalleryDirectoryPagination.vue';
import Loading from '@components/Loading.vue';
import ModalComponent from '@components/ModalComponent.vue';
import ButtonActions from '@layouts/ButtonActions.vue';
import EmptyState from '@layouts/EmptyState.vue';

const route = useRoute();
const imageStore = useImageStore();
const applicationStore = useApplicationStore();
const { images, allImages } = storeToRefs(imageStore);

const viewId = route.params['id'] as string;
const availableDates = ref<string[]>([]);
const isSearching = ref<boolean>(true);
const downloading = ref<boolean>(false);

let startOfDay = dayjs().startOf('day');
let endOfDay = dayjs().endOf('day');

const startDate = ref<Date>(startOfDay.toDate());
const endDate = ref<Date>(endOfDay.toDate());

const isImageModalVisible = ref<boolean>(false);
const isLoadingImage = ref<boolean>(true);
const selectedImage = ref<client.Image | undefined>(undefined);

const openImageModal = (image: typeof selectedImage.value): void => {
  selectedImage.value = image;
  isLoadingImage.value = true;
  isImageModalVisible.value = true;

  const img = new Image();
  img.src = image?.sourceURL || '';
  img.onload = () => {
    onImageLoad();
  };
  img.onerror = () => {
    onImageError();
  };
};

const closeImageModal = (): void => {
  selectedImage.value = undefined;
  isImageModalVisible.value = false;
};

const onImageLoad = (): void => {
  isLoadingImage.value = false;
};

const onImageError = (): void => {
  isLoadingImage.value = false;
};

const downloadUrl = computed(() => {
  return selectedImage.value ? `${selectedImage.value.sourceURL}?dl=${selectedImage.value.originalFileName}` : '';
});

async function searchImages(): Promise<void> {
  isSearching.value = true;
  await imageStore.obtainImagesByDateRange(viewId, startDate.value, endDate.value);
  isSearching.value = false;
}

onMounted(async () => {
  availableDates.value = (await client.listViewByIdCapturedDates({ viewId })).data;

  if (availableDates.value.length != 0) {
    startDate.value = new Date(availableDates.value.at(-1)!);
    endDate.value = new Date(availableDates.value.at(-1)!);
  }
  await searchImages();
});

async function downloadImages(): Promise<void> {
  try {
    downloading.value = true;
    const fileNames = allImages.value.filter((x) => x.originalFileName != undefined).map((x) => x.originalFileName!);
    await client.createBatchDownload({
      requestBody: { files: fileNames, organisation: applicationStore.activeOrganisation!.id, view: viewId },
    });
  } catch (error) {
    applicationStore.publishErrorNotification({ text: 'An internal error occurred.' });
    console.error(error);
  } finally {
    downloading.value = false;
  }
}

async function deleteImage(id: string): Promise<void> {
  try {
    await imageStore.deleteImage(id);
    applicationStore.publishSuccessNotification({ text: 'Image successfully deleted.' });
  } catch (error: any) {
    applicationStore.publishErrorNotification({ text: error.message });
    console.error(error);
  }
}
</script>

<template>
  <ContainerCard>
    <form>
      <div class="field-group">
        <div class="fields fields-datepickers">
          <div class="field">
            <label for="model">Start Date</label>
            <GalleryDatePicker v-model="startDate"
                               :available-dates="availableDates"
                               @update:model-value="
                                 (e: Date) => {
                                   startDate = e;
                                 }
                               "
                               @date-selected="searchImages" />
          </div>
          <div class="field">
            <label for="model">End Date</label>
            <GalleryDatePicker v-model="endDate"
                               :available-dates="availableDates"
                               @update:model-value="
                                 (e: Date) => {
                                   endDate = e;
                                 }
                               "
                               @date-selected="searchImages" />
          </div>

          <ButtonActions class="flex-grow-1">
            <ButtonComponent :variant="ButtonVariant.Dark"
                             :is-outline-btn="true"
                             :disabled="downloading"
                             :is-block-btn="true"
                             :to="{ name: PageNames.ViewDownloads, params: { id: viewId } }">
              View Downloads
            </ButtonComponent>
            <ButtonComponent :variant="ButtonVariant.Dark"
                             :is-block-btn="true"
                             :disabled="downloading"
                             @click="downloadImages">
              Download images
            </ButtonComponent>
          </ButtonActions>
        </div>
      </div>
    </form>
    <div>
      <Directory :object-collection-reference="images.data"
                 :loading="isSearching">
        <template #default="scope">
          <!-- @vue-ignore -->
          <GalleryCard :resource="scope.resource"
                       @on-click="openImageModal(scope.resource)"
                       @on-delete="(image: client.Image) => deleteImage(image.id)" />
        </template>

        <template #table-empty>
          <EmptyState heading-text="No images found"
                      :icon-name="IconName.PhotoIcon"
                      :icon-style="IconStyle.Outline" />
        </template>
      </Directory>

      <GalleryDirectoryPagination />
    </div>
  </ContainerCard>

  <ModalComponent :visible="isImageModalVisible"
                  :heading-title="`Download ${selectedImage?.originalFileName}`"
                  @on-close="closeImageModal">
    <template #modal-content>
      <Loading v-if="isLoadingImage" />
      <div v-else>
        <img v-if="selectedImage?.sourceURL"
             class="modal-image"
             :src="selectedImage.sourceURL"
             alt="Image preview"
             :aria-hidden="true"
             @load="onImageLoad"
             @error="onImageError">
      </div>
    </template>
    <template #modal-footer>
      <ButtonComponent :variant="ButtonVariant.Dark"
                       :is-block-btn="true"
                       :is-outline-btn="true"
                       @click="closeImageModal">
        Cancel
      </ButtonComponent>
      <a :href="downloadUrl"
         target="_blank"
         rel="noreferrer">
        <ButtonComponent :variant="ButtonVariant.Dark"
                         :is-block-btn="true"
                         :disabled="isLoadingImage || !downloadUrl">
          Download
        </ButtonComponent>
      </a>
    </template>
  </ModalComponent>
</template>

<style lang="scss" scoped>
.modal-image {
  max-height: calc(60vh - 40px);
  max-width: 100%;
  width: auto;
  height: auto;
  display: block;
  margin: auto;
  aspect-ratio: 6 / 4;
}
</style>

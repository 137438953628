<script setup lang="ts">
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { BreadcrumbPaths, ButtonSize, ButtonVariant, PageNames } from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';

import ButtonComponent from '@components/ButtonComponent.vue';
import { TableColumn } from '@components/directory/models/Table';
import TLSTable from '@components/directory/Table.vue';
import Heading from '@components/Heading.vue';
import ButtonActions from '@layouts/ButtonActions.vue';
import EmptyState from '@layouts/EmptyState.vue';

const enum TableHeaders {
  Name = 'Name',
  Actions = 'Actions',
}

const route = useRoute();

const clientId = route.params['id'] as string;

const router = useRouter();

const applicationStore = useApplicationStore();
const showNew = applicationStore.canUser(client.Entitlements.CREATE_PROJECT, applicationStore.activeOrganisation!);

async function getProjectList(): Promise<client.Project[]> {
  // Filter in API not client side
  const res = await client.listProjects({ organisation: applicationStore.activeOrganisation!.id });
  res.data = res.data.filter((x) => x.client == clientId);
  return res.data;
}

const columns: TableColumn[] = [
  {
    label: TableHeaders.Name,
    field: 'name',
    headerClasses: 'text--white-space-nowrap',
    showActions: false,
  },
  {
    label: TableHeaders.Actions,
    field: 'id',
    showActions: false,
    width: '1%',
  },
];

const table = reactive({
  columns: columns,
  sortable: {
    order: 'id',
    sort: 'asc',
  },
});

// Handle navigation
const cancelBtn = (): void => {
  const routerHistory = router.options.history;
  const backUrl = routerHistory.state['back'];

  // If previous route is login, navigate to /clients
  if (typeof backUrl === 'string' && backUrl.startsWith('/login?continueUrl=')) {
    router.push(BreadcrumbPaths.Clients as string);
  } else if (routerHistory.state['back']) {
    // If there's a valid previous route, go back
    router.go(-1);
  } else {
    router.push(BreadcrumbPaths.AllViews as string);
  }
};
</script>

<template>
  <form>
    <div class="field-group">
      <div class="field-group-info">
        <Heading level="3">
          Projects
        </Heading>
        <p>These are all the projects belonging to this client.</p>
      </div>
      <div class="fields">
        <TLSTable :retrieve-data="getProjectList"
                  :columns="columns"
                  :sortable="table.sortable">
          <template #table-empty>
            <EmptyState heading-text="No projects found"
                        :strap-line="showNew ? 'Get started by creating your first project' : ''"
                        :button-variant="ButtonVariant.Dark"
                        button-text="New Project"
                        :icon-name="IconName.ClipboardDocumentListIcon"
                        :icon-style="IconStyle.Outline"
                        :to="{ name: PageNames.ClientProjectsCreate, params: { id: clientId } }" />
          </template>
          <template #cell="{ row, column }">
            <template v-if="column.label === TableHeaders.Actions">
              <div class="d-flex justify-between gap-20">
                <ButtonComponent :to="{ name: PageNames.ProjectEdit, params: { id: row.id } }"
                                 :icon-name="IconName.AdjustmentsHorizontalIcon"
                                 :icon-style="IconStyle.Outline"
                                 :size="ButtonSize.Small"
                                 :variant="ButtonVariant.Dark">
                  Edit
                </ButtonComponent>
              </div>
            </template>
          </template>
        </TLSTable>
      </div>
    </div>

    <ButtonActions>
      <ButtonComponent :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       :is-outline-btn="true"
                       @click="cancelBtn">
        Cancel
      </ButtonComponent>
      <ButtonComponent v-if="showNew"
                       :is-block-btn="true"
                       :to="{ name: PageNames.ClientProjectsCreate, params: { id: clientId } }"
                       :variant="ButtonVariant.Dark">
        New Project
      </ButtonComponent>
    </ButtonActions>
  </form>
</template>

<script lang="ts" setup>
import { computed, onMounted, Ref, ref, useSlots, watch } from 'vue';
import { RouteLocationRaw } from 'vue-router';

import { getIconComponent } from '@utils/heroIconsMap';

import { ButtonSize, ButtonStyle, ButtonType, ButtonVariant } from '@viewModels/enums';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';

import { ArrowPathIcon } from '@heroicons/vue/24/solid';

interface ButtonProps {
  type?: ButtonType;
  variant?: ButtonVariant;
  size?: ButtonSize;
  disabled?: boolean;
  loading?: boolean;
  isBlockBtn?: boolean;
  btnStyle?: ButtonStyle;
  isOutlineBtn?: boolean;
  to?: RouteLocationRaw;
  iconName?: IconName;
  iconStyle?: IconStyle;
  iconPosition?: IconPosition;
  isIconBtn?: boolean;
  ariaLabel?: string;
  isActiveBtn?: boolean;
  isDropdownBtn?: boolean;
}

// Use the interface directly with defineProps
const props = defineProps<ButtonProps>();

// Delayed loading state
const delayedLoading = ref(false);
const loadingDelay = 1000; // 1 second delay

// Watch the loading prop and manage delayed loading state
watch(
  () => props.loading,
  (newValue) => {
    if (newValue) {
      delayedLoading.value = true;
    } else {
      setTimeout(() => {
        delayedLoading.value = false;
      }, loadingDelay);
    }
  }
);

// Define default props
const type = computed(() => props.type || ButtonType.Button);
const variant = computed(() => props.variant || ButtonVariant.Primary);
const isBlockBtn = computed(() => props.isBlockBtn || false);
// Compute the ariaLabel based on whether the button is icon-only
const computedAriaLabel = computed(() => {
  if (props.isIconBtn) {
    return props.ariaLabel || '';
  }
  return props.ariaLabel || '';
});
// Compute the icon position
const computedIconPosition = computed(() => {
  return props.iconPosition || IconPosition.Left;
});

// Define emitted events for parent communication
const emit = defineEmits<{
  (e: 'click', event: Event): void;
  (e: 'toggleDropdown', isOpen: boolean): void;
}>();

// Manage dropdown open/close state if used as a dropdown button
const isOpen = ref(false) as Ref<boolean>;

// Determine the tag to be rendered (button or router-link)
const buttonTag = computed(() => (props.to ? 'router-link' : 'button'));

// Flag to check if the default slot has content
const hasDefaultSlot = ref(false);

// Check for default slot content when component is mounted
onMounted(() => {
  const slots = useSlots();
  hasDefaultSlot.value = !!slots['default'];
});

// Emit click event and handle dropdown toggle
function handleClick(event: Event): void {
  emit('click', event);
  toggleDropdown();
}

// Toggle dropdown state if applicable (only if isDropdownBtn is true)
function toggleDropdown(): void {
  if (props.isDropdownBtn) {
    isOpen.value = !isOpen.value;
    emit('toggleDropdown', isOpen.value);
  }
}
</script>

<template>
  <component :is="buttonTag"
             :aria-disabled="disabled || delayedLoading"
             :aria-label="computedAriaLabel"
             :class="[
               'btn',
               `btn--${variant || ButtonVariant.Dark}`,
               `btn--${btnStyle || ButtonStyle.Rounded}`,
               { [`btn--${size}`]: size },
               { 'btn--loading': delayedLoading, 'btn--mobile': isBlockBtn },
               { [`btn-outline--${variant || ButtonVariant.Dark}`]: isOutlineBtn },
               { 'btn--icon-only': isIconBtn },
               { 'btn--disabled': disabled && !to },
               { 'btn--active': isActiveBtn },
               { 'btn--dropdown': isDropdownBtn, open: isOpen },
             ]"
             :disabled="disabled && !to"
             :to="to"
             :type="!to ? type || ButtonType.Button : null"
             @click="handleClick">
    <span ref="content"
          class="btn__content">
      <!-- Left icon or loading spinner -->
      <template v-if="iconName && computedIconPosition === IconPosition.Left">
        <ArrowPathIcon v-if="delayedLoading"
                       aria-label="Loading"
                       class="btn__spinner" />
        <component :is="getIconComponent(iconName as IconName, iconStyle as IconStyle)"
                   v-else
                   aria-hidden="true"
                   class="btn__icon" />
      </template>

      <!-- Loading spinner when no icon on the left -->
      <template v-if="!iconName && delayedLoading">
        <ArrowPathIcon aria-label="Loading"
                       class="btn__spinner" />
      </template>

      <!-- Text slot -->
      <slot v-if="!isIconBtn" />

      <!-- Right icon or loading spinner -->
      <template v-if="iconName && computedIconPosition === IconPosition.Right">
        <ArrowPathIcon v-if="delayedLoading"
                       aria-label="Loading"
                       class="btn__spinner" />
        <component :is="getIconComponent(iconName as IconName, iconStyle as IconStyle)"
                   v-else
                   aria-hidden="true"
                   class="btn__icon" />
      </template>
    </span>
  </component>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

// Color/Theme variables
$primary-color: $primary-color;
$primary-hover: $neutral-800;
$secondary-color: $gold-600;
$secondary-hover: $gold-900;
$success-color: $green-600;
$success-hover: $green-800;
$danger-color: $red-800;
$danger-hover: $red-900;
$warning-color: $orange-400;
$warning-hover: $orange-800;
$info-color: $blue-600;
$info-hover: $blue-800;
$light-color: $neutral-300;
$light-hover: $neutral-200;
$dark-color: $neutral-600;
$dark-hover: $neutral-800;
$transparent: transparent;
$inherit-color: inherit;

$link-color: $primary-color;
$link-hover: $primary-color;
$white-color: $neutral-50;
$black-color: $neutral-800;
$box-shadow-color: $black-opacity-25;
$focus-color: var(--secondary-color, $secondary-color);

// Mixin for button styles
@mixin button-style($bg-color, $hover-color, $text-color: $white-color, $active-bg-color: $hover-color) {
  background-color: $bg-color;
  color: $text-color;

  &:hover {
    background-color: $hover-color;
    text-decoration: none;
  }

  &.btn--active {
    background-color: $active-bg-color;
    pointer-events: none;
  }
}

// Mixin for outline button styles
@mixin button-outline-style(
  $border-color,
  $hover-bg-color,
  $hover-text-color: $white-color,
  $active-bg-color: $hover-bg-color,
  $active-text-color: $hover-text-color
) {
  background-color: transparent;
  color: $border-color;
  box-shadow: inset 0 0 0 1px $border-color;

  &:hover {
    background-color: $hover-bg-color;
    color: $hover-text-color;
    text-decoration: none;
  }

  &.btn--active {
    background-color: $active-bg-color;
    color: $active-text-color;
  }
}

.btn {
  $root: &;

  align-items: center;
  border: none;
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  justify-content: center;
  min-height: 40px;
  max-height: 40px;
  min-width: 120px;
  outline: none;
  padding: 10px 20px;
  transition:
    background-color 0.3s ease,
    color 0.3s ease,
    box-shadow 0.3s ease,
    outline 0.3s ease;
  white-space: nowrap;
  width: min-content;

  &--rounded {
    border-radius: 5px;
  }

  &--pill {
    border-radius: 50px;
  }

  &--rectangle {
    border-radius: 0;
  }

  &__content {
    display: inline-flex;
    align-items: center;
    column-gap: 8px;
    line-height: 1.2;
  }

  &--icon-only {
    align-items: center;
    display: inline-flex;
    height: 40px;
    justify-content: center;
    min-width: 40px;
    padding: 0;
    width: 40px;

    & #{$root}__icon {
      height: 1.7rem;
      width: 1.7rem;
    }

    &#{$root}--small {
      height: 32px;
      min-width: 32px;
      width: 32px;

      & #{$root}__icon {
        height: 1.4rem;
        width: 1.4rem;
      }
    }

    &#{$root}--large {
      height: 54px;
      min-width: 54px;
      width: 54px;

      & #{$root}__icon {
        height: 2rem;
        width: 2rem;
      }
    }
  }

  &--dropdown {
    & #{$root}__icon {
      transition: transform 0.15s ease;
      transform: rotate(0deg);
    }

    &.open {
      & #{$root}__icon {
        transform: rotate(180deg);
      }
    }
  }

  &--primary {
    @include button-style($primary-color, $primary-hover);
  }

  &--secondary {
    @include button-style($secondary-color, $secondary-hover);
  }

  &--success {
    @include button-style($success-color, $success-hover);
  }

  &--danger {
    @include button-style($danger-color, $danger-hover);
  }

  &--warning {
    @include button-style($warning-color, $warning-hover, $dark-color);
  }

  &--info {
    @include button-style($info-color, $info-hover);
  }

  &--light {
    @include button-style($light-color, $light-hover, $black-color);
  }

  &--dark {
    @include button-style($dark-color, $dark-hover);
  }

  &--transparent {
    @include button-style($transparent, $transparent, $inherit-color);
  }

  &--link {
    background-color: transparent;
    color: $link-color;

    &:hover {
      box-shadow: none;
      color: $link-hover;
      filter: invert(20%);
      text-decoration: underline;
    }

    &:focus,
    &:active {
      box-shadow: none;
    }
  }

  &--loading {
    cursor: not-allowed;
    opacity: 0.65;
  }

  &--small {
    font-size: 0.875rem;
    max-height: 32px;
    min-height: 32px;
    min-width: 70px;
    padding: 8px 10px;

    & #{$root}__icon,
    & #{$root}__spinner {
      height: 0.875rem;
      width: 0.875rem;
    }
  }

  &--large {
    font-size: 1.25rem;
    max-height: 54px;
    min-height: 54px;
    min-width: 120px;
    padding: 15px 25px;

    & #{$root}__icon,
    & #{$root}__spinner {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  &--disabled {
    opacity: 0.65;
    pointer-events: none;
  }

  button[disabled] {
    opacity: 0.65;
    pointer-events: none;
  }

  &__spinner {
    animation: Rotate 0.75s linear infinite;
    height: 1.3rem;
    width: 1.3rem;
  }

  &__icon {
    height: 1.3rem;
    width: 1.3rem;

    &.btn--small & {
      height: 1rem;
      width: 1rem;
    }

    &.btn--large & {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  &--mobile {
    @media only screen and (max-width: $breakpoint-lg) {
      width: 100%;
    }
  }

  /* Outline button styles */
  &.btn-outline--primary {
    @include button-outline-style($primary-color, $primary-color);
  }

  &.btn-outline--secondary {
    @include button-outline-style($secondary-color, $secondary-color);
  }

  &.btn-outline--success {
    @include button-outline-style($success-color, $success-color);
  }

  &.btn-outline--danger {
    @include button-outline-style($danger-color, $danger-color);
  }

  &.btn-outline--warning {
    @include button-outline-style($warning-color, $warning-color, $dark-color);
  }

  &.btn-outline--info {
    @include button-outline-style($info-color, $info-color);
  }

  &.btn-outline--light {
    @include button-outline-style($light-color, $light-color, $dark-color);
  }

  &.btn-outline--dark {
    @include button-outline-style($dark-color, $dark-color, $white-color);
  }

  &.btn-outline--transparent {
    @include button-outline-style($transparent, $inherit-color);
  }

  &:focus-visible {
    box-shadow: inset 0 0 0 2px $focus-color;
    outline: none;
  }
}
</style>

<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { ButtonType, ButtonVariant, PageNames } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Heading from '@components/Heading.vue';
import SubHeader from '@components/SubHeader.vue';
import ButtonActions from '@layouts/ButtonActions.vue';

interface ClientCreationForm {
  systemSerialNumber: string;
  wpSerialNumber: string;
  wpImei: string;
}

const schema = yup.object({
  systemSerialNumber: yup.string().required(),
});

const { handleSubmit } = useForm<ClientCreationForm>({
  validationSchema: schema,
});

const { value: systemSerialNumberValue, errorMessage: systemSerialNumberError } = useField<string>(
  'systemSerialNumber',
  'systemSerialNumber'
);

const router = useRouter();
const applicationStore = useApplicationStore();
const isSubmitting = ref<boolean>(false);

const onSubmit = handleSubmit(async (values: any) => {
  isSubmitting.value = true;

  if (!applicationStore.activeOrganisation) {
    return;
  }

  await client.registerCamera({
    requestBody: {
      serialNumber: values.systemSerialNumber,
      organisation: applicationStore.activeOrganisation.id,
    },
  });
  isSubmitting.value = false;

  applicationStore.publishSuccessNotification({
    text: 'Successfully created camera.',
    autoCloseMs: 3000,
  });
  router.push({ name: PageNames.Cameras });
});
</script>

<template>
  <SubHeader heading="Register Camera"
             level="2" />

  <ContainerCard>
    <form @submit="onSubmit">
      <div class="field-group">
        <div class="field-group-info">
          <Heading level="3">
            Camera Information
          </Heading>
          <p>Add your camera details and register it in the system.</p>
        </div>

        <div class="fields">
          <div class="row-half">
            <div class="field">
              <label for="camera-serial">GabrielCam Serial Number</label>
              <input id="system-serial"
                     v-model="systemSerialNumberValue"
                     inputmode="numeric"
                     type="text">
              <p class="message message-error">
                {{ systemSerialNumberError }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <ButtonActions>
        <ButtonComponent :variant="ButtonVariant.Dark"
                         :disabled="isSubmitting"
                         :is-block-btn="true"
                         :type="ButtonType.Submit">
          Register
        </ButtonComponent>
      </ButtonActions>
    </form>
  </ContainerCard>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';

import * as client from '@gabrielcam/api-client';

import { convertToScheduleViewModel } from '@utils/ScheduleMapper';

import { useScheduleServiceStore } from '@stores/schedule';
import { ScheduleViewModel } from '@viewModels/scheduleViewModel';

import CameraSchedulePendingSection from '@components/camera/CameraSchedulePendingSection.vue';
import Heading from '@components/Heading.vue';

import CameraScheduleSection from './CameraScheduleSection.vue';

const scheduleStore = useScheduleServiceStore();
var allScheduleSections: ScheduleViewModel[] = [];
const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
const hasMonthlySchedule = ref(false);
const hasRapidCapture = ref<boolean>(false);
const shadow = ref<client.Shadow>();

const props = defineProps<{
  cameraId: string;
}>();

function Setup(): void {
  if (scheduleStore.advancedCameraScheduleEnabled) {
    SetupAdvancedSchedules();
    return;
  }
  SetupSingleSchedule();
}

function SetupSingleSchedule(): void {
  var schedule: ScheduleViewModel = new ScheduleViewModel();
  if (allScheduleSections.length !== 0) schedule = allScheduleSections[0] ?? new ScheduleViewModel();
  schedule.month = 'ALL';
  scheduleStore.monthlySheduleSections.push(schedule);
}

function SetupAdvancedSchedules(): void {
  months.forEach((month) => {
    var currentMonths = allScheduleSections.filter((x) => x.month.toUpperCase() == month.toUpperCase());
    if (currentMonths.length === 0 || currentMonths.at(0) == undefined) {
      const defaultCameraSchedule: ScheduleViewModel = new ScheduleViewModel();
      defaultCameraSchedule.month = month.toUpperCase();

      scheduleStore.monthlySheduleSections.push(defaultCameraSchedule);
      return;
    }
    const currentMonth = currentMonths.at(0);
    if (currentMonth != undefined) scheduleStore.monthlySheduleSections.push(currentMonth);
  });
}

onMounted(async () => {
  scheduleStore.monthlySheduleSections = [];
  allScheduleSections = await scheduleStore.GetCameraSchedules();
  Setup();

  shadow.value = await client.getCameraByIdShadow({ cameraId: props.cameraId });
  if (shadow?.value?.state?.reported != undefined) {
    const scheduleStartTime = shadow.value.state.reported['scheduleStartTime'] as number;
    const scheduleEndTime = shadow.value.state.reported['scheduleEndTime'] as number;
    const scheduleRate = shadow.value.state.reported['scheduleRate'] as number;
    const scheduleDays = shadow.value.state.reported['scheduleDays'] as number;
    scheduleStore.monthlySheduleSections[0] = convertToScheduleViewModel({
      scheduleDays: scheduleDays,
      scheduleEndTime: scheduleEndTime,
      scheduleRate: scheduleRate,
      scheduleStartTime: scheduleStartTime,
    });

    // Log the comparison of current and pending schedules
    console.log('Current and Pending Schedules:');
    const combinedSchedule = scheduleStore.monthlySheduleSections.map((currentSchedule, index) => {
      const pendingSchedule = allScheduleSections[index] || {};
      return {
        Current: currentSchedule,
        Pending: pendingSchedule,
      };
    });
    console.table(combinedSchedule[0]);
  }
});

function openMonth(month: string): void {
  visibleMonth.value = month.toUpperCase();
}

function toggleAdvancedCameraSchedule(): void {
  scheduleStore.monthlySheduleSections = [];
  Setup();
}

const date = new Date();
const month = date.toLocaleString('default', { month: 'short' });
const visibleMonth = ref<string>(month.toUpperCase());
</script>

<template>
  <div class="field-group">
    <div class="field-group-info">
      <Heading level="3">
        Camera Schedule
      </Heading>
      <p>
        Choose the days and the time that you'd like the camera to take photos.
        <span v-if="hasMonthlySchedule">These can be set per month for greater flexibility.</span>
      </p>
      <p v-if="hasRapidCapture">
        Rapid capture allows you to increase the capture rate for 1 or 2 hours of the day.
      </p>
      <div v-if="hasMonthlySchedule"
           class="fields">
        <div class="checkbox-field">
          <label for="advanced-camera">Monthly Schedule</label>
          <input id="advanced-camera"
                 v-model="scheduleStore.advancedCameraScheduleEnabled"
                 type="checkbox"
                 @change="toggleAdvancedCameraSchedule">
        </div>
      </div>
    </div>
    <div :class="{ 'hidden-navigation': !scheduleStore.advancedCameraScheduleEnabled }"
         class="tabs">
      <div class="tab-navigation">
        <button v-for="currentMonth in months"
                :key="currentMonth"
                :class="{ active: currentMonth === visibleMonth }"
                class="tab-button"
                type="button"
                @click="openMonth(currentMonth)">
          {{ currentMonth }}
        </button>
      </div>
      <div class="tab-content">
        <div v-for="(scheduleSection, i) in scheduleStore.monthlySheduleSections"
             :id="scheduleSection.month"
             :key="i"
             class="tab">
          <div v-show="scheduleSection.month == visibleMonth || !scheduleStore.advancedCameraScheduleEnabled"
               class="tab-panel">
            <CameraScheduleSection v-model:camera-schedule="scheduleStore.monthlySheduleSections[i]" />
            <CameraSchedulePendingSection v-if="false"
                                          :camera-schedule="scheduleSection" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@scss/variables' as *;

.tabs {
  width: 100%;

  .tab-navigation {
    .tab-button {
      font-size: 0.7em;
      background: #fff;
      color: $neutral-800;
      border: none;
      border-radius: 5px 5px 0 0;
      padding: 10px 2px;
      margin: 0 6px 0 0;
      outline: 1px solid $neutral-200;
      @media screen and (min-width: 500px) {
        font-size: 0.8em;
      }
      @media screen and (min-width: 550px) {
        padding: 10px 4px;
      }
      @media screen and (min-width: 620px) {
        width: 7.2%;
      }

      &:hover,
      &:focus,
      &:focus-visible,
      &.active {
        background-color: $neutral-200;
      }
    }
  }

  .tab-content {
    background: #fff;
    border: 1px solid $neutral-200;
    padding: 15px;
    margin: -2px 0 0 -1px;
    position: relative;
  }
}

.hidden-navigation .tab-navigation {
  display: none;
}
</style>

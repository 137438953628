<script setup lang="ts">
import { onMounted, Ref, ref } from 'vue';

import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';

import * as client from '@gabrielcam/api-client';

import { ButtonType, ButtonVariant } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';
import ButtonActions from '@layouts/ButtonActions.vue';

const props = defineProps<{
  signIn: Function;
}>();

const form = ref<InstanceType<typeof HTMLFormElement>>();
const isSubmitting: Ref<boolean> = ref(false);
const expiredLink = ref(false);
const magicLinkSent = ref(false);

interface LoginWithEmailAndPasswordForm {
  email: string;
}

const schema = yup.object({
  email: yup.string().email().required(),
});

const { handleSubmit } = useForm<LoginWithEmailAndPasswordForm>({
  validationSchema: schema,
});

const { value: emailValue, errorMessage: emailError } = useField<string>('email', 'email', {
  validateOnValueUpdate: false,
  modelPropName: 'email',
});

onMounted(() => {
  const signInEmail = localStorage.getItem('signInEmail');
  if (signInEmail) {
    emailValue.value = signInEmail;
    localStorage.removeItem('signInEmail');
    form.value?.dispatchEvent(new Event('submit'));
  }
});

const sendMagicLink = async (): Promise<void> => {
  try {
    const signInEmail = localStorage.getItem('signInEmail');
    if (signInEmail) {
      await client.sendMagicLink({ requestBody: { email: signInEmail } });
      magicLinkSent.value = true;
    }
  } catch (error) {
    console.error(error);
  }
  expiredLink.value = false;
};

const onSubmit = handleSubmit(async (values, actions) => {
  isSubmitting.value = true;
  try {
    await props.signIn(values.email);
  } catch (error: any) {
    if (error.code === 'auth/invalid-action-code') {
      expiredLink.value = true;
      localStorage.setItem('signInEmail', values.email);
      actions.setErrors({ email: 'Expired link' });
      console.error('Expired link');
    } else if (error.code === 'auth/invalid-email') {
      actions.setErrors({ email: 'Email does not match' });
      console.error('Email does not match');
    } else {
      actions.setErrors({ email: 'Error occurred' });
      console.error('Error occurred');
    }
    isSubmitting.value = false;
    return;
  }
});
</script>

<template>
  <div v-if="magicLinkSent">
    <p>Magic link sent. Check your email.</p>
  </div>

  <form v-else
        ref="form"
        @submit="onSubmit">
    <div class="fields">
      <div class="field">
        <label for="email-address">Email address</label>
        <input id="email-address"
               v-model="emailValue"
               type="email"
               autocomplete="email">
        <p v-dompurify-html="emailError"
           class="message message-error" />
      </div>
    </div>

    <ButtonActions>
      <ButtonComponent v-if="expiredLink"
                       :variant="ButtonVariant.Dark"
                       :is-block-btn="true"
                       @click="sendMagicLink">
        Send new link
      </ButtonComponent>
      <ButtonComponent v-else
                       :disabled="!expiredLink"
                       :loading="isSubmitting"
                       :type="ButtonType.Submit"
                       :variant="ButtonVariant.Dark"
                       :is-block-btn="true">
        Login
      </ButtonComponent>
    </ButtonActions>
  </form>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

import { getAuth, signOut } from 'firebase/auth';

import * as client from '@gabrielcam/api-client';

import { firebaseApp } from '@utils/firebase';

import { resetAllStores } from '@stores/util';
import { PageNames } from '@viewModels/enums';

import AccountLayout from '@layouts/AccountLayout.vue';

const router = useRouter();
onMounted(async () => {
  resetAllStores();
  await signOut(getAuth(firebaseApp));
  client.OpenAPI.TOKEN = undefined;
  await router.push({ name: PageNames.Login, query: { message: 'Successfully logged out.' } });
});
</script>

<template>
  <AccountLayout>
    <p>You are now logged out...</p>
  </AccountLayout>
</template>
